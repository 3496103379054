@tailwind base;
@tailwind components;
@tailwind utilities;


input{
  accent-color: #E6570E !important;
}

.feddup-bg {
  background-color: #E6570E;
}

.feddup-grey-bg {
  background-color: grey;
}

.opacity {
  opacity: 0.5;
}


.error {
  color: red;
  font-size: 9px;
  font-family: Poppins;
  font-weight: normal !important;
  height: 0px;
  margin-top: -12px;
}

.tag-data-fill {
  position: relative;
  top: -25px;
  color: white;
  left: 10px;
  font-size: 14px;
}

.tag-data-fill_ {
  position: relative;
  top: -25px;
  color: white;
  left: 10px;
  font-size: 14px;
}

.tag-data-empty {
  position: relative;
  top: -25px;
  color: grey;
  left: 10px;
  font-size: 14px;
}

.tag-data-empty_ {
  position: relative;
  top: -25px;
  color: grey;
  font-size: 14px;
  left: 10px;
}

#talkbubble {
  width: auto;
  position: relative;
}

#talkbubble-select {
  width: auto;
  position: relative;
}

#talkbubble-unselect {
  width: auto;
  position: relative;
}

#talkbubble:before {
  content: "";
  position: absolute;
  right: 99.8%;
  top: 0;
  width: 0;
  height: 0;
  font-family: Poppins, sans-serif !important;
  border-top: 12px solid transparent;
  border-right: 12px solid #FF8B66;
  border-bottom: 12px solid transparent;
}

.tags-btn {
  background-color: #FF8B66;
  width: auto;
  height: 24px;
  border-color: #FF8B66;
  margin: auto;
  margin-left: 1.4rem;
  border-radius: 2px;
  color: white;
  font-family: Poppins, sans-serif !important;
  font-size: 12px;
}

.tags-btn:hover {
  background-color: #FF8B66;
  color: white;
  border-color: #FF8B66;
}

.tags-btn-unselect {
  background-color: #ffffff;
  width: auto;
  height: 24px;
  border: 1px solid lightgrey !important;
  margin: auto;
  margin-left: 1.4rem;
  border-radius: 2px;
  color: #AAAAAA;
  font-family: Poppins, sans-serif !important;
  font-size: 12px;
}

.tags-btn-unselect:hover {
  background-color: #ffffff;
  color: #AAAAAA;
  border: 1px solid lightgrey !important;
}

#talkbubble-select:before {
  content: "";
  position: absolute;
  right: 98.5%;
  top: 0;
  width: 0;
  height: 0;
  font-family: Poppins, sans-serif !important;
  border-top: 12px solid transparent;
  border-right: 12px solid #FF8B66;
  border-bottom: 12px solid transparent;
}

#talkbubble-unselect:before {
  content: "";
  position: absolute;
  right: 99.8%;
  top: -1px;
  width: 0;
  height: 0;
  font-family: Poppins, sans-serif !important;
  border-top: 12px solid transparent;
  border-right: 12px solid lightgrey;
  border-bottom: 12px solid transparent;
}

.drag-drop {
  width: 100%;
  border: 1px solid lightgrey;
  border-radius: 10px;
  padding: 0.5rem 0.5rem 1.3rem 0.5rem;
  cursor: pointer;
}

.i-flex {
  display: flex;
}

.fontStyle {
  font-family: Poppins, sans-serif !important;
  font-size: 10px;
}

.b-image {
  border: 1px solid lightgrey;
  width: 100%;
  height: 35px;
  padding: 0.1rem;
  border-radius: 10px;
  color: grey;
}
.uploadImageContainer label{
  width: 100%;
}

/* .imessage {
    display: flex;
    flex-direction: column;
    font-family: "Poppins";
    font-size: 1.25rem;
    margin: 0 auto 1rem;
    max-width: 600px;
    padding: 0.5rem 1.5rem;
  }
  
  .imessage p {
    border-radius: 1.15rem;
    line-height: 1.25;
    max-width: 75%;
    padding: 0.5rem .875rem;
    position: relative;
    word-wrap: break-word;
  }
  
  .imessage p::before,
  .imessage p::after {
    bottom: -0.1rem;
    content: "";
    height: 1rem;
    position: absolute;
  }
  
  p.from-me {
    align-self: flex-end;
    background-color: #e7ebed;
    border-color:#e7ebed;
  }
  
  p.from-me::before {
    border-bottom-left-radius: 0.8rem 0.7rem;
    border-right: 1rem solid #e7ebed;;
    right: -0.35rem;
    transform: translate(0, -0.1rem);
    border-color:#e7ebed;
  }
  
  p.from-me::after {
    background-color: #fff;
    border-bottom-left-radius: 0.5rem;
    right: -40px;
    transform:translate(-30px, -2px);
    width: 10px;
    border-color:#e7ebed;
  }
  
  p[class^="from-"] {
    margin: 0.5rem 0;
    width: fit-content;
  }
  
  p.from-me ~ p.from-me {
    margin: 0.25rem 0 0;
  }
  
  p.from-me ~ p.from-me:not(:last-child) {
    margin: 0.25rem 0 0;
  }
  
  p.from-me ~ p.from-me:last-child {
    margin-bottom: 0.5rem;
  }
  
  p[class^="from-"].emoji {
    background: none;
    font-size: 2.5rem;
  }
  
  p[class^="from-"].emoji::before {
    content: none;
  }
  
  .no-tail::before {
    display: none;
  }
  
  .margin-b_none {
    margin-bottom: 0 !important;
  }
  
  .margin-b_one {
    margin-bottom: 1rem !important;
  }
  
  .margin-t_one {
    margin-top: 1rem !important;
  } */


@layer components {

  .feddup__fullscreen__bg {
    @apply fixed w-full h-screen overflow-hidden opacity inset-0
  }

  .feddup__icon_position {
    @apply fixed bottom-8 right-7 flex flex-col items-end
  }

  .feddup__container {
    @apply max-w-xs w-full min-w-[300px] bg-white border p-4 my-4 relative rounded-md
  }

  .feddup__popup__tail {
    @apply w-4 h-4 bg-white transform rotate-45 absolute -bottom-2 right-1 border-b border-r
  }

  .feddup__container__topbar {
    @apply flex items-center justify-between w-full gap-4
  }

  .feddup__container__topbar_details {
    @apply flex items-center gap-2
  }

  .feddup__container__topbar_details__box {
    @apply absolute top-6 right-0 bg-gray-50 py-2 px-4 shadow-lg rounded-lg hidden group-hover:inline-block min-w-[150px]
  }

  .feddup__container__topbar_details__title {
    @apply text-lg font-semibold flex-shrink-0
  }

  .feddup__text-gray-500 {
    @apply text-gray-500
  }

  .feddup__popup__text-lg{
    @apply text-lg
  }

  .feddup__text-xs {
    @apply text-xs
  }

  .feddup__svg__icon {
    @apply w-5 cursor-pointer
  }

  .feddup__signup__text {
    @apply text-gray-600 text-sm mt-2 mb-1
  }

  .feddup__popup__button {
    @apply flex justify-center items-center gap-1 w-full max-w-[260px] text-white rounded-2xl text-base font-medium mx-auto py-2 px-6 my-2
  }

  .feddup__popup__info__container {
    @apply absolute hidden group-hover:inline-block bottom-3 right-7 min-w-max py-2 px-4
  }

  .feddup__popup__info__container__text {
    @apply absolute bottom-12 right-9 text-base text-gray-900 max-w-[270px] text-center font-medium
  }

  .feddup__popup__info__container__text a {
    @apply text-orange-500 hover:text-orange-700 hover:underline
  }

  .feddup__popup__rating__topbar {
    @apply flex flex-col items-center justify-center gap-4 py-6
  }
  
  .feddup__popup__rating__topbar button {
    @apply flex justify-center items-center gap-1 w-full max-w-[260px] text-white rounded-2xl feddup-bg text-base text-center font-medium mx-auto py-2 px-6 my-4
  }
  
  .feddup__popup__rating__icon__container{
    @apply flex items-center justify-around mb-4 w-full
  }

  .feddup__popup__rating__container__textarea{
    @apply w-full border border-gray-300 rounded p-2 resize-none h-32
  }

  .feddup__popup__tag__wrapper{
    @apply flex flex-wrap gap-y-2
  }

  .feddup__popup__rating__container__input{
    @apply w-full border border-gray-300 rounded p-2 resize-none h-10
  }
  .feddup__popup__rating__container__submit{
    @apply block w-full max-w-[260px] text-white rounded-2xl text-base font-medium mx-auto py-2 px-6 my-4
  }

  .feddup__popup__feedback__validation__div{
    @apply absolute bottom-12 right-8 bg-gray-50 min-w-max py-2 px-4 shadow-lg rounded-lg hidden group-hover:inline-block
  }
 
  .feddup__popup__questionnaire__nav{
    @apply flex items-center justify-between py-4 w-full
  }
  .feddup__popup__questionnaire__nav .back{
    @apply py-2 px-4 rounded-3xl inline-flex items-center gap-1 text-white bg-gray-500 font-medium
  }
  .feddup__popup__questionnaire__nav .next{
    @apply py-2 px-4 rounded-3xl inline-flex items-center gap-1 text-white feddup-bg font-medium
  }
  .feddup__popup__questionnaire__checkbox__input{
    @apply mr-2 accent-orange-400 w-4 h-4
  }

  .feddup__popup__text__count{
    @apply text-right absolute bottom-3 right-3
  }
  .feddup__popup__questionnaire__radio_icon_container{
    @apply flex items-center justify-around my-4 w-full
  }



  .feddup__popup__login{
    @apply fixed w-full h-screen overflow-hidden bg-transparent inset-0 grid place-items-center p-3
  }
  .feddup__popup__login__container{
    @apply w-full max-w-xl bg-white border rounded-lg shadow-lg p-5 px-10 min-h-[70vh]
  }
  .feddup__popup__login__nav{
    @apply flex items-center justify-between w-full
  }
  .feddup__popup__login__nav .close{
    @apply w-6 h-6 cursor-pointer hover:text-orange-600
  }

  .feddup__popup__login__form{
    @apply w-full flex flex-col items-center justify-center max-w-sm mx-auto
  }
  .feddup__popup__login__form__heading{
    @apply text-2xl font-bold text-center lg:text-3xl my-2
  }

  .feddup__popup__login__form__input{
    @apply border my-4 py-1 px-2 rounded outline-orange-300 w-full bg-white text-lg
  }
  .feddup__popup__login__form__forget{
    @apply mt-3 text-orange-500 hover:text-orange-700 hover:underline w-full underline-offset-4 underline
  }
  .feddup__popup__login__form__submit__container{
    @apply bg-orange-400 w-[120px] my-8 h-8 border-2 border-[#FFB858] rounded-[10px] rotate-6 inset-0
  }
  .feddup__popup__login__form__submit__container .submit{
    @apply bg-orange-600 text-white border-orange-600 text-lg rounded-[10px] w-[116px] h-8 font-medium leading-tight -rotate-6
  }
  .feddup__popup__login__form__register{
    @apply text-orange-500 hover:text-orange-700 hover:underline w-full
  }

  .feddup__popup__login__loading__container{
    @apply w-full min-h-[50vh] grid place-items-center
  }
  .feddup__popup__login__loading__container__loader{
    @apply rounded-full border-8 border-orange-600 border-l-transparent w-24 h-24 animate-spin
  }
  .feddup__popup__login__success__container{
    @apply w-full min-h-[50vh] flex flex-col items-center justify-center gap-4
  }
  .feddup__popup__login__success__container__message{
    @apply text-xl font-medium text-center my-4 max-w-xs
  }
  .feddup__popup__login__success__container__button{
    @apply py-1.5 px-12 rounded-md bg-orange-600 text-white font-medium text-lg
  }


  .feddup__popup__rating__review__confirmationText{
    @apply text-sm font-semibold mb-3
  }
  .feddup__popup__rating__review__ratingText{
    @apply mt-1 text-base
  }
  .feddup__popup__rating__review__feedbackText__div{
    @apply flex justify-end items-end h-full
  }
  .feddup__popup__rating__review__feedbackText{
    @apply text-black text-xl mx-3 invisible
  }
  
  .feddup__popup__rating__review__confirmation__container{
    @apply flex flex-col gap-10 justify-between
  }
  .feddup__popup__rating__review__confirmation__container__options{
    @apply flex flex-col gap-6
  }
  .feddup__popup__rating__review__confirmation__container__option{
    @apply bg-[#FBFBFB] flex flex-col gap-1 p-3 h-[10rem] rounded-md cursor-pointer border-2 border-transparent hover:border-[#FFB633]
  }
  .feddup__popup__rating__review__confirmation__container__option__title{
    @apply text-sm font-semibold
  }
  .feddup__popup__rating__review__confirmation__container__option__description{
    @apply text-sm font-normal
  }
  .feddup__popup__rating__review__confirmation__container__option__image{
    @apply ml-auto w-10
  }
  .feddup__popup__rating__review__confirmation__container__actions{
    @apply flex justify-between items-center gap-6
  }
  .feddup__popup__rating__review__confirmation__container__action__cancel{
    @apply text-orange-600 text-lg p-2 cursor-pointer
  }
  .feddup__popup__rating__review__confirmation__container__action__skip{
    @apply flex justify-end items-center gap-3
  }
  .feddup__popup__rating__review__confirmation__container__action__skip p{
    @apply text-base leading-normal cursor-pointer text-orange-400 
  }

  
  .feddup__popup__rating__review__rating__container{
    @apply mt-6 flex flex-col justify-between
  }
  .feddup__popup__rating__review__rating__container__ratings{
    @apply flex w-full justify-center items-center gap-1 cursor-pointer mb-3
  }
  .feddup__popup__rating__review__rating__container__rating{
    @apply flex flex-col items-center h-14 gap-1
  }
  .feddup__popup__rating__review__rating__container__rating__image{
    @apply w-12 h-12 cursor-pointer resize-none
  }
  .feddup__popup__rating__review__rating__container__rating__span{
    @apply text-xs font-medium
  }
  .feddup__popup__rating__review__rating__container__rating__textarea{
    @apply border placeholder-gray-500 text-black focus:text-black focus:ring-0 w-full p-2
  }
  .feddup__popup__rating__review__rating__container__rating__submitDiv{
    @apply bg-orange-400 w-[120px] h-8 border-2 border-[#FFB858] rounded-[10px] rotate-6 inset-0
  }
  .feddup__popup__rating__review__rating__container__rating__submitBtn{
    @apply bg-orange-600 text-white border-orange-600 text-lg rounded-[10px] w-[116px] h-8 font-medium leading-tight -rotate-6
  }
  .feddup__popup__rating__review__rating__container__rating__next{
    @apply text-white border-orange-600 rounded-xl py-3 px-2 w-20 leading-[0px] h-6
  }
  .feddup__popup__rating__review__rating__container__someText{
    @apply absolute bottom-8 -right-5 bg-gray-50 min-w-max p-2 rounded-lg hidden group-hover:inline-block
  }
  .feddup__popup__rating__review__feedback__container{
    @apply flex flex-col justify-between
  }
  .feddup__popup__rating__review__feedback__container__row{
    @apply flex justify-between rounded-xl
  }
  .feddup__popup__rating__review__feedback__container__col{
    @apply mt-2 w-[30rem] h-[25rem]
  }

}