@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

.feddup__icon_position {
    color: #222 !important;
    font-family: 'Poppins', sans-serif !important;
}

#feddup12345 {
    font-family: 'Poppins', sans-serif !important;
}


#feddupDiv p {
    font-family: 'Poppins', sans-serif !important;
}

#feddupDiv h1 {
    font-family: 'Poppins', sans-serif !important;
}

#feddupDiv span {
    font-family: 'Poppins', sans-serif !important;
}

#feddupDiv button {
    font-family: 'Poppins', sans-serif !important;
}

#feddupDiv label {
    font-family: 'Poppins', sans-serif !important;
}